@import 'clearkit/dist/clearkit.css';
@import './css/_tailwind.css';

/* Disable CSS animations while resizing the window */
.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

input {
  font-family: inherit;
  font-size: inherit;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  background-color: #fff;
  font-family: 'Avenir Next', Avenir, Helvetica, Arial, sans-serif;
  color: #27282a;
}

button {
  font-family: 'Avenir Next', Avenir, Helvetica, Arial, sans-serif;
}

a,
.link-button {
  color: #148cfc;
  text-decoration: none;
}

strong {
  font-weight: 500;
}

.ReactVirtualized__Grid {
  outline: none;
}

#root {
  height: 100%;
  overflow: auto;
}

.react-hot-loader-error-overlay > div {
  max-height: 100vh;
  z-index: 10000;
}

/* // Overrides for Google Charts tooltips */
.google-visualization-tooltip {
  color: #fff !important;
  padding: 10px 15px !important;
  font-size: 14px !important;
  max-width: 200px !important;
  min-height: 0 !important;
  box-shadow: 0px 1px 1.5px 0 rgba(59, 64, 67, 0.16),
    0px 5.5px 16px 0 rgba(59, 64, 67, 0.1) !important;
  font-family: 'Avenir Next', Avenir, Helvetica, Arial, sans-serif !important;
  line-height: 1.14286em !important;
  border: none !important;
  border-radius: 8px !important;
  background-color: rgba(59, 67, 83, 0.96) !important;
}

.google-visualization-tooltip .google-visualization-tooltip-item-list {
  margin: 0px !important;
  padding: 0px !important;
}

.google-visualization-tooltip .google-visualization-tooltip-item {
  margin: 0px !important;
  padding: 0px !important;
}

.google-visualization-tooltip .google-visualization-tooltip-item span {
  display: none !important;
}

.hide-if-empty:empty {
  display: none;
}

.popover-max-height {
  max-height: 80vh;
}

.x-sidebar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
}

.dashboard-graph-tooltip span span {
  padding: 0 10px;
}

.dashboard-graph-tooltip span span:first-of-type {
  font-size: 12px !important;
  font-weight: 500;
}

.border-ck-box-separator {
  border-color: #e1e6eb;
}

.last-border-b-0:last-child {
  border-bottom: none;
}

.ck-header-border-scroll {
  --border: 0 0 0 1px var(--gray-200);

  background: rgb(255, 255, 255, 0.95);
  box-shadow: var(--border);
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Currently supported in latest Chrome
falls back to border for other browsers */
@supports (animation-timeline: scroll()) {
  .ck-header-border-scroll {
    box-shadow: none;
    animation-duration: 1ms;
    animation-fill-mode: both;
    animation-name: scroll-border;
    animation-range: 0ex 5ex;
    animation-timeline: scroll();
    animation-timing-function: ease-in;
  }

  @keyframes scroll-border {
    from {
      box-shadow: none;
    }

    to {
      box-shadow: var(--border);
    }
  }
}
