.notifications-fade-enter {
  opacity: 0;
}
.notifications-fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.notifications-fade-exit {
  opacity: 1;
}
.notifications-fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.forms-theme-default .cb-submission {
  padding: 15px;
  display: flex;
  justify-content: center;
  font-family: 'Avenir Next', Avenir, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-weight: normal;
  font-size: 13px;
  text-transform: none;
}

/* *** Overlay *** */

.cb-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(82, 95, 127, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation-fill-mode: forwards;
  box-sizing: border-box;
}

.cb-overlay .cb-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  min-width: 460px;
  min-height: 260px;
  background: #fff;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 30px;
  box-shadow: 0 0 0 1px rgba(136, 152, 170, 0.1),
    0 15px 35px 0 rgba(49, 49, 93, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.13);
}

.cb-overlay.cb-active {
  animation: cb-overlay-fade-in 300ms;
  animation-fill-mode: forwards;
}

.cb-overlay.cb-active .cb-modal {
  animation: overlay-modal-in 300ms;
  animation-fill-mode: forwards;
}

.cb-overlay.cb-inactive {
  animation: cb-overlay-fade-out 300ms;
  animation-fill-mode: forwards;
}

.cb-overlay .cb-submission.cb-loaded {
  animation: cb-submission-loaded 300ms;
  animation-fill-mode: forwards;
  overflow: auto;
}

@keyframes cb-submission-loaded {
  from {
    opacity: 0;
    max-height: 300px;
  }

  50% {
    opacity: 0;
    max-height: 600px;
  }

  to {
    opacity: 1;
    max-height: 600px;
  }
}

@keyframes cb-overlay-fade-in {
  from {
    background-color: rgba(82, 95, 127, 0);
  }

  to {
    background-color: rgba(82, 95, 127, 0.25);
  }
}

@keyframes cb-overlay-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes overlay-modal-in {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0) scale(0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.forms-theme-default .cb-response {
  font-size: 20px;
}

/* *** Forms *** */

.forms-theme-default h2 {
  font-weight: 400;
  margin: 0 0 15px 0;
}

.forms-theme-default .cb-loading {
  color: #212425;
}

.forms-theme-default .cb-form {
  width: 100%;
  flex: none;
}

@media only screen and (min-width: 768px) {
  .forms-theme-default .cb-form {
    width: 370px;
  }
}

.forms-theme-default .cb-submit {
  padding: 20px 0 0 0;
}

.forms-theme-default button {
  display: block;
  width: 100%;
  cursor: pointer;
  outline: none;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;

  color: #ffffff;
  min-width: 179px;
  min-height: 38px;
  box-shadow: 0 1px 1px 0 rgba(52, 88, 133, 0.16);
  border: none;
  transition: all 0.15s ease;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 7px;
  background-color: #f8f9f9;
  background-image: linear-gradient(-180deg, #338af6 0%, #6c86d9 96%);
  font-family: 'Avenir Next', Avenir, Helvetica, Arial, sans-serif;
}

.forms-theme-default button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  background-color: #32383f;
}

.forms-theme-default button.cb-pending {
  opacity: 0.5;
  cursor: default;
}

.forms-theme-default .cb-field {
  padding: 10px 0;
  position: relative;
}

.forms-theme-default .cb-field .cb-checkbox-container {
  display: flex;
  align-items: center;
}

.forms-theme-default .cb-field .cb-checkbox-container > p {
  font-size: 14px;
  margin: 0 0 0 8px;
}

.forms-theme-default .cb-field .cb-checkbox-container > p > a {
  color: #85bbff;
}

.forms-theme-default input[type='text'],
.forms-theme-default input[type='email'],
.forms-theme-default input[type='tel'],
.forms-theme-default input[type='select'],
.forms-theme-default input[role='combobox'] {
  appearance: none;
  -webkit-appearance: none;
  display: block;
  height: 43px;
  width: 100%;
  padding: 0 16px;
  color: #212425;
  font-size: 14px;
  line-height: 1.36;
  outline: none;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e8e9ec;
  box-shadow: 0 1px 1px 0 rgba(243, 244, 245, 0.65);
  border-radius: 5px;
  transition: border-color 300ms;
}

.forms-theme-default input:focus,
.forms-theme-default textarea:focus {
  border-color: #85bbff;
}

.forms-theme-default input::placeholder,
.forms-theme-default textarea::placeholder {
  opacity: 0.4;
  font-family: AvenirNext-Regular;
  font-size: 15px;
  color: #414953;
}

.forms-theme-default select {
  appearance: none;
  -webkit-appearance: none;
  display: block;
  height: 43px;
  width: 100%;
  padding: 0 16px;
  color: #414953;
  font-size: 14px;
  line-height: 1.36;
  outline: none;
  background: #ffffff;
  border: 1px solid #e8e9ec;
  box-shadow: 0 1px 1px 0 rgba(243, 244, 245, 0.65);
  border-radius: 5px;
}

.forms-theme-default textarea {
  appearance: none;
  -webkit-appearance: none;
  display: block;
  min-height: 100px;
  width: 100%;
  padding: 16px;
  border-radius: 5px;
  border: solid 1px #e4e7ea;
  background-color: #fff;
  color: #212425;
  font-size: 14px;
  line-height: 1.36;
  outline: none;
  box-sizing: border-box;
}

.forms-theme-default label {
  font-size: 13px;
  font-weight: 500;
  color: #545a61;
  margin: 0 0 5px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  transition: color 300ms;
  user-select: none;
}

.forms-theme-default .cb-field.cb-focus label {
  color: #85bbff;
}

.forms-theme-default .cb-select {
  position: relative;
  cursor: pointer;
}

.forms-theme-default .cb-arrow {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -4px;
  pointer-events: none;
}

.forms-theme-default .cb-field.cb-error input,
.forms-theme-default .cb-field.cb-error textarea {
  border-color: #f44336;
}

.forms-theme-default .cb-field.cb-error label {
  color: #f44336;
}

.forms-theme-default .cb-field .cb-errors {
  position: absolute;
  right: 0;
  top: 10px;
  color: #f44336;
  font-size: 13px;
  font-weight: normal;
}

.forms-theme-default .cb-autocomplete {
  border-radius: 3px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 43px;
  left: 0;
  right: 0;
  overflow: auto;
  z-index: 10;
}

.forms-theme-default .cb-suggestion {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.forms-theme-default .cb-suggestion.cb-hl {
  background: #5786d7;
  color: #fff;
}

.forms-theme-default .cb-suggestion img {
  width: 25px;
  display: block;
}

.forms-theme-default .cb-suggestion .cb-name {
  padding: 0 10px;
  flex: 1 1 auto;
}

.forms-theme-default .cb-attribution {
  text-align: right;
}

.forms-theme-default .cb-attribution a {
  font-size: 10px;
  color: #a9b0b8;
  text-decoration: none;
}

.forms-theme-default .cb-attribution a:hover {
  text-decoration: underline;
}
